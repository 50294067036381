ul, li {
    font-family: sans-serif;
}
ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
}
ul.header {
    background-color: #FF995B;
    padding: 0;
}
ul.header li a {
    color: #FFF;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
}